import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import {
  DownOutlined
} from "@ant-design/icons";

const UserInfo = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  console.log(authUser,"authUser")
  const dispatch = useDispatch();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><a href="/account/profile" >My Account</a></li>
      <li><a href="/account/changepassword" >Change Password</a></li>
      {/* <li>Connections</li> */}
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      {authUser.FirstName+" "+authUser.LastName}<DownOutlined />
    </Popover>
  );
};

export default UserInfo;
