import React from "react";
// import loader from "../../assets/images/loader.svg";
import logo from "../../assets/images/EF-mono.jpg";

const CircularProgress = ({ className }) => (
  <div className={`loader ${className}`}>
    <img src={logo} alt="loader" />
  </div>
);
export default CircularProgress;
