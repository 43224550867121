import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({ match }) => {
  // console.log(match);
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}main`}
          component={asyncComponent(() => import("./Main"))}
        />
        <Route
          path={`${match.url}moderation`}
          component={asyncComponent(() => import("./Moderation"))}
        />
        <Route
          path={`${match.url}questionMerge`}
          component={asyncComponent(() => import("./QuestionMerge"))}
        />
        <Route
          path={`${match.url}members`}
          component={asyncComponent(() => import("./Members"))}
        />
        <Route
          path={`${match.url}membershipAdmin`}
          component={asyncComponent(() => import("./MembershipAdmin"))}
        />
        <Route
          path={`${match.url}siteConfig`}
          component={asyncComponent(() => import("./SiteConfig"))}
        />
        <Route
          path={`${match.url}censor`}
          component={asyncComponent(() => import("./Censorship"))}
        />
        <Route
          path={`${match.url}topics`}
          component={asyncComponent(() => import("./Topics"))}
        />
        <Route
          path={`${match.url}userReports`}
          component={asyncComponent(() => import("./UserReports"))}
        />
        <Route
          path={`${match.url}manage`}
          component={asyncComponent(() => import("./Manage"))}
        />
        <Route
          path={`${match.url}extras`}
          component={asyncComponent(() => import("./Extra"))}
        />
        <Route
          path={`${match.url}account`}
          component={asyncComponent(() => import("./Account"))}
        />
      </Switch>
    </div>
  );
};

export default App;
