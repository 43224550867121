import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
// import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";
import {
  // NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import MenuItem from "antd/lib/menu/MenuItem";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );

  // const getNoHeaderClass = (navStyle) => {
  //   if (
  //     navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
  //     navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
  //   ) {
  //     return "gx-no-header-notifications";
  //   }
  //   return "";
  // };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content" style={{ height: "100%" }}>
        {/* <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <MenuItemGroup key="main" className=" gx-menu-group" title="Main">
              <MenuItem key="main/dashboard">
                <Link to="/main/dashboard">
                  <i className="icon icon-dasbhoard" />
                  <span>
                    <IntlMessages id="sidebar.dashboard" />
                  </span>
                </Link>
              </MenuItem>
            </MenuItemGroup>
            <MenuItemGroup
              key="moderation"
              className=" gx-menu-group"
              title="Moderation"
            >
              <MenuItem key="moderation/pendingPosts">
                <Link to="/moderation/pendingPosts">
                  <i className="icon icon-wysiwyg" />
                  <span>Pending Posts</span>
                </Link>
              </MenuItem>
              <MenuItem key="moderation/stats">
                <Link to="/moderation/stats">
                  <i className="icon icon-chart" />
                  <span>Online Mods/Admins</span>
                </Link>
              </MenuItem>
              <MenuItem key="moderation/history">
                <Link to="/moderation/history">
                  <i className="icon icon-eye" />
                  <span>History</span>
                </Link>
              </MenuItem>
            </MenuItemGroup>
            <MenuItemGroup
              key="userReports"
              className=" gx-menu-group"
              title="Reports From Users"
            >
              <MenuItem key="userReports/questions">
                <Link to="/userReports/questions">
                  <i className="icon fas fa-question" />
                  <span>Questions</span>
                </Link>
              </MenuItem>
              {/*<MenuItem key="userReports/questionHistory">
                <Link to="/userReports/questionHistory">
                  <i className="icon fas fa-history" />
                  <span>Question History</span>
                </Link>
              </MenuItem>*/}
              <MenuItem key="userReports/answers">
                <Link to="/userReports/answers">
                  <i className="icon icon-sweet-alert" />
                  <span>Answers</span>
                </Link>
              </MenuItem>
              {/*<MenuItem key="userReports/answerHistory">
                <Link to="/userReports/answerHistory">
                  <i className="icon fas fa-history" />
                  <span>Answer History</span>
                </Link>
              </MenuItem>*/}
              <MenuItem key="userReports/comments">
                <Link to="/userReports/comments">
                  <i className="icon fas fa-comments" />
                  <span>Comments</span>
                </Link>
              </MenuItem>
              {/*<MenuItem key="userReports/commentHistory">
                <Link to="/userReports/commentHistory">
                  <i className="icon fas fa-history" />
                  <span>Comment History</span>
                </Link>
              </MenuItem>*/}
            </MenuItemGroup>
            <MenuItemGroup
              key="manage"
              className=" gx-menu-group"
              title="Manage"
            >
              <MenuItem key="manage/questions">
                <Link to="/manage/questions">
                  <i className="icon fas fa-question" />
                  <span>Questions</span>
                </Link>
              </MenuItem>
              <MenuItem key="manage/answers">
                <Link to="/manage/answers">
                  <i className="icon fas fa-exclamation" />
                  <span>Answers</span>
                </Link>
              </MenuItem>
            </MenuItemGroup>
            <MenuItemGroup
              key="questionMerge"
              className=" gx-menu-group"
              title="Merge Question"
            >
              <MenuItem key="questionMerge/merge">
                <Link to="/questionMerge/merge">
                  <i className="icon icon-add-circle" />
                  <span>Merge</span>
                </Link>
              </MenuItem>
              <MenuItem key="questionMerge/history">
                <Link to="/questionMerge/history">
                  <i className="icon icon-eye" />
                  <span>History</span>
                </Link>
              </MenuItem>
            </MenuItemGroup>
            <MenuItemGroup
              key="siteConfig"
              className=" gx-menu-group"
              title="Site Configuration"
            >
              <SubMenu
                key="settings"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-company" />
                    <span>Settings</span>
                  </span>
                }
              >
                <MenuItem key="siteConfig/settings/basic">
                  <Link to="/siteConfig/settings/basic">
                    <span>Basic</span>
                  </Link>
                </MenuItem>
                {/*<MenuItem key="siteConfig/settings/apps">
                  <Link to="/siteConfig/settings/apps">
                    <span>Applications</span>
                  </Link>
                </MenuItem>
                <MenuItem key="siteConfig/settings/general">
                  <Link to="/siteConfig/settings/general">
                    <span>General</span>
                  </Link>
                </MenuItem>
                <MenuItem key="siteConfig/settings/email">
                  <Link to="/siteConfig/settings/email">
                    <span>Email</span>
                  </Link>
                </MenuItem>*/}
                <MenuItem key="siteConfig/settings/noIndex">
                  <Link to="/siteConfig/settings/noIndex">
                    <span>No Index</span>
                  </Link>
                </MenuItem>
                <MenuItem key="siteConfig/settings/metaTag">
                  <Link to="/siteConfig/settings/metaTag">
                    <span>Meta Tag</span>
                  </Link>
                </MenuItem>
              </SubMenu>
              <MenuItem key="siteConfig/ranks">
                <Link to="/siteConfig/ranks">
                  <i className="icon fas fa-trophy" />
                  <span>Ranks</span>
                </Link>
              </MenuItem>
              <MenuItem key="siteConfig/badges">
                <Link to="/siteConfig/badges">
                  <i className="icon fas fa-id-badge" />
                  <span>Badges</span>
                </Link>
              </MenuItem>
              <MenuItem key="siteConfig/ipBans">
                <Link to="/siteConfig/ipBans">
                  <i className="icon fas fa-ban" />
                  <span>IP Bans</span>
                </Link>
              </MenuItem>
              <MenuItem key="siteConfig/301redirects">
                <Link to="/siteConfig/301redirects">
                  <i className="icon fas fa-redo" />
                  <span>301 Redirects</span>
                </Link>
              </MenuItem>
              <MenuItem key="siteConfig/emojiFilter">
                <Link to="/siteConfig/emojiFilter">
                  <i className="icon fas fa-laugh" />
                  <span>Emoticons Filter</span>
                </Link>
              </MenuItem>
            </MenuItemGroup>
            <MenuItemGroup
              key="censor"
              className=" gx-menu-group"
              title="Censorship"
            >
              <MenuItem key="censor/username">
                <Link to="/censor/username">
                  <i className="icon fas fa-address-card" />
                  <span>Username Filter</span>
                </Link>
              </MenuItem>
              <MenuItem key="censor/word">
                <Link to="/censor/word">
                  <i className="icon fas fa-italic" />
                  <span>Word Filter</span>
                </Link>
              </MenuItem>
              <MenuItem key="censor/domain">
                <Link to="/censor/domain">
                  <i className="icon fas fa-network-wired" />
                  <span>Domain Filter</span>
                </Link>
              </MenuItem>
            </MenuItemGroup>
            <MenuItemGroup
              key="topics"
              className=" gx-menu-group"
              title="Topics"
            >
              <MenuItem key="topics/manage">
                <Link to="/topics/manage">
                  <i className="icon fas fa-tasks" />
                  <span>Manage</span>
                </Link>
              </MenuItem>
              <MenuItem key="topics/create">
                <Link to="/topics/create">
                  <i className="icon fas fa-heading" />
                  <span>Create</span>
                </Link>
              </MenuItem>
            </MenuItemGroup>
            <MenuItemGroup
              key="membershipAdmin"
              className=" gx-menu-group"
              title="Membership Administration"
            >
              <MenuItem key="membershipAdmin/create">
                <Link to="/membershipAdmin/create">
                  <i className="icon fas fa-user-circle" />
                  <span>Create New Account</span>
                </Link>
              </MenuItem>
              <MenuItem key="membershipAdmin/manageUsers">
                <Link to="/membershipAdmin/manageUsers">
                  <i className="icon fas fa-users" />
                  <span>Manage Users</span>
                </Link>
              </MenuItem>
              <MenuItem key="membershipAdmin/roles">
                <Link to="/membershipAdmin/roles">
                  <i className="icon icon-company" />
                  <span>Manage User Roles</span>
                </Link>
              </MenuItem>
              {/*<SubMenu
                key="account"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-components" />
                    <span>Account Settings</span>
                  </span>
                }
              >
                <MenuItem key="membershipAdmin/account/registration">
                  <Link to="/membershipAdmin/account/registration">
                    <span>Registration</span>
                  </Link>
                </MenuItem>
                <MenuItem key="membershipAdmin/account/newUserExp">
                  <Link to="/membershipAdmin/account/newUserExp">
                    <span>New User Experience</span>
                  </Link>
                </MenuItem>
                <MenuItem key="membershipAdmin/account/invitation">
                  <Link to="/membershipAdmin/account/invitation">
                    <span>Invitation</span>
                  </Link>
                </MenuItem>
                <MenuItem key="membershipAdmin/account/friendship">
                  <Link to="/membershipAdmin/account/friendship">
                    <span>Friendship</span>
                  </Link>
                </MenuItem>
                <MenuItem key="membershipAdmin/account/profile">
                  <Link to="/membershipAdmin/account/profile">
                    <span>Profile</span>
                  </Link>
                </MenuItem>
                <MenuItem key="membershipAdmin/account/avatar">
                  <Link to="/membershipAdmin/account/avatar">
                    <span>Avatar</span>
                  </Link>
                </MenuItem>
              </SubMenu>*/}
              <MenuItem key="membershipAdmin/memberSearch">
                <Link to="/membershipAdmin/memberSearch">
                  <i className="icon fas fa-search" />
                  <span>Member Search Settings</span>
                </Link>
              </MenuItem>
            </MenuItemGroup>
            {/* ---------------------------------------------------------------------- */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
